import React, { Component } from 'react'
import { navigate } from 'gatsby'
import dateFns, { addDays } from 'date-fns'
import axios from 'axios'
import Form from 'react-jsonschema-form'

import {
    Button,
    Badge,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const schema = {
    type: 'object',
    required: ['name', 'time', 'date'],
    properties: {
        name: {
            type: 'string',
            title: 'Cleaner Name',
            enum: ['unassign', 'Kathleen', 'Rhiannon', 'Rheannah', 'Other'],
            enumNames: [
                'Unassign',
                'Kathleen',
                'Rhiannon',
                'Rheannah',
                'Other',
            ],
        },
        date: {
            type: 'string',
            title: 'Date',
        },
        time: {
            type: 'string',
            title: 'Scheduled Time',
            enum: [
                'unassign',
                '9am',
                '9:30am',
                '10:00am',
                '10:30am',
                '11:00am',
                '11:30am',
                '12:00pm',
                '12:30pm',
                '1:00pm',
                '1:30pm',
                '2:00pm',
                '2:30pm',
                '3:00pm',
                '3:30pm',
                '4:00pm',
                '4:30pm',
                '5:00pm',
                '5:30pm',
            ],
            enumNames: [
                'Unassign',
                '9am',
                '9:30am',
                '10:00am',
                '10:30am',
                '11:00am',
                '11:30am',
                '12:00pm',
                '12:30pm',
                '1:00pm',
                '1:30pm',
                '2:00pm',
                '2:30pm',
                '3:00pm',
                '3:30pm',
                '4:00pm',
                '4:30pm',
                '5:00pm',
                '5:30pm',
            ],
        },
    },
}

const uiSchema = {
    date: {
        'ui:widget': 'date',
    },
}

class BookingsPage extends Component {
    state = {
        date: new Date(),
        bookings: [],
        modal: false,
        booking: {},
    }
    componentDidMount() {
        const auth = window.localStorage.getItem('auth')

        if (!auth) return navigate('/')
        axios.defaults.headers.common['Authorization'] = `Bearer ${auth}`

        this.getBookingsForMonth()
    }
    getBookingsForMonth = async () => {
        try {
            const { data: bookings } = await axios
                .get(
                    'https://api.kindled.com.au/api/booking/5a560f0ca8e513002e5dbc87/cleaner'
                )
                .catch(error => {
                    throw Error(error.response.status)
                })

            this.setState({ bookings, modal: false })
        } catch (error) {
            console.log(error.message)
            window.localStorage.removeItem('auth')
            return navigate('/')
        }
    }
    assignCleanerToBooking = async cleaner => {
        if (cleaner.name === 'unassign' || cleaner.time === 'unassign')
            cleaner = null

        await axios.post(
            `https://api.kindled.com.au/api/booking/single/${
                this.state.booking._id
            }/cleaner`,
            cleaner
        )

        this.getBookingsForMonth()
    }

    listFood = (upgrades, type) => {
        const list = []
        upgrades.map((upgrade, index) => {
            if (!upgrade) return false
            if (
                type === 'alcohol' &&
                upgrade.item.icon.match(/^wine$|^beer$|^champagne$/)
            )
                list.push(<li key={`upgrade-${index}`}>{upgrade.item.name}</li>)
            if (
                type === 'food' &&
                upgrade.item.icon.match(/^bbq$|^vegetarian$|^food$|^bacon$/)
            )
                list.push(<li key={`upgrade-${index}`}>{upgrade.item.name}</li>)
        })

        return list
    }

    renderBookings = () => {
        function isAssigned(assignedCleaner) {
            if (assignedCleaner) return 'active'
        }
        return this.state.bookings.map((booking, i) => {
            const previousBooking = i < 1 ? {} : this.state.bookings[i - 1]
            if (!previousBooking.end) {
                previousBooking.end = new Date(booking.start)
            }

            let alcohol = false
            let food = false
            let inhouse = false

            booking.upgrades.map(upgrade => {
                if (!upgrade) return false
                if (upgrade.item.icon.match(/^wine$|^beer$|^champagne$/))
                    alcohol = true
                if (
                    upgrade.item.icon.match(/^bbq$|^vegetarian$|^food$|^bacon$/)
                )
                    food = true
                if (upgrade.item.icon.match(/^campfire$/)) inhouse = true
                return upgrade
            })

            return (
                <Card className="mb-4" key={booking._id}>
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col>
                                <h5 className="mb-0">Cleaning Window</h5>
                                {!booking.cleaner ? (
                                    <Badge color="danger" pill>
                                        Unassigned
                                    </Badge>
                                ) : (
                                    <div>
                                        <Badge
                                            color="success"
                                            pill
                                            className="mr-2"
                                        >
                                            {booking.cleaner.name}
                                        </Badge>
                                        <Badge color="info" pill>
                                            {booking.cleaner.time}{' '}
                                            {dateFns.format(
                                                new Date(booking.cleaner.date),
                                                'DD/MM/YYYY'
                                            )}
                                        </Badge>
                                    </div>
                                )}
                            </Col>
                            <Col className="text-right">
                                {!booking.cleaner ? (
                                    <Button
                                        color="outline-primary"
                                        onClick={() => this.toggle(booking)}
                                    >
                                        Assign
                                    </Button>
                                ) : (
                                    <Button
                                        color="outline-primary"
                                        onClick={() => this.toggle(booking)}
                                    >
                                        Reassign
                                    </Button>
                                )}
                                {/* <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                        caret
                                        color="outline-primary"
                                    >
                                        {!booking.cleaner
                                            ? 'Assign'
                                            : 'Reassign'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Kathleen</DropdownItem>
                                        <DropdownItem>Rhiannon</DropdownItem>
                                        <DropdownItem>Rheannah</DropdownItem>
                                        <DropdownItem>Other</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown> */}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className={isAssigned(booking.cleaner)}>
                        <div className="text-center d-flex flex-column">
                            <Badge color="secondary">
                                <span className="h4">
                                    {dateFns.format(
                                        new Date(previousBooking.end),
                                        'ddd DD/MM/YY [11:00am]'
                                    )}
                                </span>
                            </Badge>
                            <p className="h1 jumbo mb-2 mt-0 text-muted">↕</p>
                            <Badge color="secondary">
                                <span className="h4">
                                    {dateFns.format(
                                        new Date(booking.start),
                                        'ddd DD/MM/YY [3:00pm]'
                                    )}
                                </span>
                            </Badge>
                        </div>
                        {booking.guests > 2 && (
                            <Alert color="info" className="mt-3 mb-0">
                                <strong>{booking.guests} Guests</strong>
                                <br />
                                <i>
                                    Please leave extra linen and out on the
                                    lounge for extra guests.
                                </i>
                            </Alert>
                        )}

                        {(alcohol || food || inhouse) && (
                            <div>
                                {alcohol && (
                                    <Alert
                                        color="warning"
                                        className="mt-3 mb-0"
                                    >
                                        <strong>Alcohol Required</strong>
                                        <br />
                                        <p>
                                            <i>
                                                Stock is located at tiny house.
                                            </i>
                                        </p>
                                        <ul className="mb-0">
                                            {this.listFood(
                                                booking.upgrades,
                                                'alcohol'
                                            )}
                                        </ul>
                                    </Alert>
                                )}
                                {food && (
                                    <Alert
                                        color="warning"
                                        className="mt-3 mb-0"
                                    >
                                        <strong>Food Pack Required</strong>
                                        <br />
                                        <p>
                                            <i>
                                                Will require a pick up from
                                                Bathurst Co-op.
                                            </i>
                                        </p>
                                        <ul className="mb-0">
                                            {this.listFood(
                                                booking.upgrades,
                                                'food'
                                            )}
                                        </ul>
                                    </Alert>
                                )}
                                {inhouse && (
                                    <Alert
                                        color="warning"
                                        className="mt-3 mb-0"
                                    >
                                        <strong>S'mores Kit Required</strong>
                                        <br />
                                        <i>Not required during summer.</i>
                                    </Alert>
                                )}
                            </div>
                        )}
                    </CardBody>
                </Card>
            )
        })
    }

    toggle = booking => {
        this.setState({
            modal: !this.state.modal,
            booking,
        })
    }

    onSubmit = ({ formData }) => {
        this.assignCleanerToBooking(formData)
    }

    render() {
        return (
            <Layout>
                <SEO
                    title={`Bookings for ${dateFns.format(
                        this.state.date,
                        'DD/MM/YYYY'
                    )} - ${dateFns.format(
                        addDays(this.state.date, 14),
                        'DD/MM/YYYY'
                    )}`}
                    keywords={['Kindled', 'Cleaning', 'Operational']}
                />
                <h2 className="mb-4">
                    {`Bookings for ${dateFns.format(
                        this.state.date,
                        'DD/MM/YYYY'
                    )} - ${dateFns.format(
                        addDays(this.state.date, 14),
                        'DD/MM/YYYY'
                    )}`}
                </h2>

                <div>{this.renderBookings()}</div>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Assign</ModalHeader>
                    <ModalBody>
                        <Form
                            formData={this.state.booking.cleaner || {}}
                            schema={schema}
                            uiSchema={uiSchema}
                            onSubmit={this.onSubmit}
                        >
                            <div>
                                <Button type="submit" color="outline-primary">
                                    Assign
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </Layout>
        )
    }
}
export default BookingsPage
